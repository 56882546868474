<template>
    <div>
        <div class="page-title">主体信息</div>

        <pay-box title="营业执照" sub-title="请上传营业执照，需年检章齐全，当年注册除外">

            <div class="pay-apply-item has-tips">

                <div class="pay-apply-item-title">营业执照照片</div>

                <div class="pay-apply-item-content">
                    <div class="thumb-img">

                        <upload-image
                                v-model="params.business_license_info.img_url"
                                :done="busLicUploadDone"
                                :action="uploadBusLicUrl"
                                class="pay-apply-upload"
                        />

                    </div>
                </div>
            </div>

            <div class="pay-apply-tips">请上传彩色照片 or 彩色扫描件 or 加盖公章鲜章的复印件，可添加“微信支付”相关水印（如微信支付认证）</div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">注册号</div>

                <div class="pay-apply-item-content">
                    <at-input v-model="params.business_license_info.license_number" class="pay-apply-input"/>
                </div>

            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">营业执照注册地址</div>

                <div class="pay-apply-item-content">
                    <at-input v-model="params.business_license_info.license_address" class="pay-apply-input"/>
                </div>

            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">商户名称</div>

                <div class="pay-apply-item-content">
                    <at-input v-model="params.business_license_info.merchant_name" class="pay-apply-input"/>
                </div>

            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">经营者姓名</div>

                <div class="pay-apply-item-content">
                    <at-input v-model="params.business_license_info.legal_person" class="pay-apply-input"/>
                </div>

            </div>


        </pay-box>


        <pay-box title="法人证件" sub-title="请上传法人的证件信息">

            <div class="pay-apply-item has-tips">

                <div class="pay-apply-item-title">身份证人像面照片</div>

                <div class="pay-apply-item-content">
                    <div class="thumb-img">

                        <upload-image
                                v-model="params.identity_info.id_card_info.img_a_url"
                                :action="uploadIdCardAUrl"
                                :done="idCardADone"
                                class="pay-apply-upload"
                        />

                    </div>
                </div>
            </div>

            <div class="pay-apply-item has-tips">

                <div class="pay-apply-item-title">身份证国徽面照片</div>

                <div class="pay-apply-item-content">
                    <div class="thumb-img">

                        <upload-image
                                v-model="params.identity_info.id_card_info.img_b_url"
                                :action="uploadIdCardBUrl"
                                :done="idCardBDone"
                                class="pay-apply-upload"
                        />

                    </div>
                </div>
            </div>

            <div class="pay-apply-tips">请上传彩色照片 or 彩色扫描件 or 加盖公章鲜章的复印件，可添加“微信支付”相关水印（如微信支付认证）</div>


            <div class="pay-apply-item">

                <div class="pay-apply-item-title">身份证姓名</div>

                <div class="pay-apply-item-content">
                    <at-input v-model="params.identity_info.id_card_info.id_card_name" class="pay-apply-input"/>
                </div>

            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">身份证件号码</div>

                <div class="pay-apply-item-content">
                    <at-input v-model="params.identity_info.id_card_info.id_card_number" class="pay-apply-input"/>
                </div>

            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">身份证居住地址</div>

                <div class="pay-apply-item-content">
                    <at-input v-model="params.identity_info.id_card_info.id_card_address" class="pay-apply-input"/>
                </div>

            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">证件有效期类型</div>

                <div class="pay-apply-item-content">

                    <a-radio-group @change="idCardTypeChanged" v-model="hp.idCardType">
                        <a-radio :value="1">定期</a-radio>
                        <a-radio :value="2">长期</a-radio>
                    </a-radio-group>

                </div>

            </div>

            <div class="pay-apply-item">

                <div class="pay-apply-item-title">证件生效日期</div>

                <div class="pay-apply-item-content">

                    <a-date-picker
                            v-model="params.identity_info.id_card_info.card_period_begin"
                            class="date-picker pay-apply-input"
                            format="YYYY-MM-DD"
                            placeholder="选择日期"
                    />

                </div>

            </div>

            <div class="pay-apply-item" v-if=" hp.idCardType === 1 ">

                <div class="pay-apply-item-title">证件失效日期</div>

                <div class="pay-apply-item-content">

                    <a-date-picker
                            v-model="params.identity_info.id_card_info.card_period_end"
                            class="date-picker pay-apply-input"
                            format="YYYY-MM-DD"
                            placeholder="选择日期"
                    />

                </div>

            </div>

        </pay-box>

        <div class="pay-apply-btns">
            <button @click="changeStep(2)" class="btn btn-white">返回</button>
            <button @click="changeStep(4)" class="btn">下一步</button>
        </div>

    </div>
</template>

<script>
    import PayBox from "./PayBox";
    import AtInput from "../../../../components/AtInput";
    import UploadImage from "../../../../components/UploadImage";
    import api from "../../../../repo/api";

    export default {
        name: "Step3",
        components: {UploadImage, AtInput, PayBox},
        props: {
            apply: Object
        },
        data() {
            return {
                hp: {
                    idCardType: 1
                },
                params: {
                    business_license_info: {
                        license_copy: '',
                        license_number: '', // 注册号/统一社会信用代码
                        merchant_name: '', // 商户名称
                        legal_person: '', // 个体户经营者/法人姓名
                        license_address: '',
                        img_url: '',
                    },
                    identity_info: {
                        id_holder_type: 'LEGAL',
                        id_doc_type: 'IDENTIFICATION_TYPE_IDCARD',
                        id_card_info: {
                            id_card_copy: '', // 身份证人像面照片
                            id_card_national: '',//身份证国徽面照片
                            id_card_name: '',   //身份证姓名
                            id_card_number: '', //身份证号码
                            id_card_address: '', // 身份证居住地址
                            card_period_begin: '', // 身份证有效期开始时间
                            card_period_end: '', // 身份证有效期结束时间
                            img_a_url: '',
                            img_b_url: '',
                        }
                    }

                }
            }
        },
        mounted() {
            if (this.apply.subject_info.business_license_info) {
                this.params.business_license_info = this.apply.subject_info.business_license_info
            }

            if (this.apply.subject_info.identity_info) {
                this.params.identity_info = this.apply.subject_info.identity_info
            }
            
            if(this.apply.subject_info.identity_info.id_card_info.card_period_end === '长期'){
                this.hp.idCardType = 2
            }
        },
        methods: {
            changeStep(step) {
                this.saveChange(step)
            },
            busLicUploadDone(res) {

                this.params.business_license_info.legal_person = res['legal_representative']
                this.params.business_license_info.license_address = res['address']
                this.params.business_license_info.license_number = res['reg_num']
                this.params.business_license_info.merchant_name = res['enterprise_name']
                this.params.business_license_info.license_copy = res['media_id']
                this.params.business_license_info.img_url = res['img_url']

            },
            idCardADone(res) {

                this.params.identity_info.id_card_info.id_card_copy = res['media_id']
                this.params.identity_info.id_card_info.img_a_url = res['img_url']

                this.params.identity_info.id_card_info.id_card_name = res['name']
                this.params.identity_info.id_card_info.id_card_number = res['id']
                this.params.identity_info.id_card_info.id_card_address = res['addr']

            },
            idCardBDone(res) {

                this.params.identity_info.id_card_info.id_card_national = res['media_id']
                this.params.identity_info.id_card_info.img_b_url = res['img_url']

                this.params.identity_info.id_card_info.card_period_begin = res['date_begin']
                this.params.identity_info.id_card_info.card_period_end = res['date_end']


                this.hp.idCardType = res['date_end'] === '长期' ? 2 : 1
            },
            idCardTypeChanged(val) {

                this.params.identity_info.id_card_info.card_period_end
                    = val === 1 ? '' : '长期'

            },
            saveChange(step) {

                this.$loading.show()

                api.post('/pay-apply/save-body-info', {subject_info: this.params}, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) this.$message.data(data)

                    if (data.code === 0) {
                        this.$emit('change', step, data.data)
                    }
                })
            }
        },
        computed: {
            uploadBusLicUrl() {
                return api.colUrl('/pay-apply/upload-business-license')
            },
            uploadIdCardAUrl() {
                return api.colUrl('/pay-apply/upload-id-card-a')
            },
            uploadIdCardBUrl() {
                return api.colUrl('/pay-apply/upload-id-card-b')
            }

        }
    }
</script>

<style lang="less" scoped>
    .date-picker {

        /deep/ .ant-input {
            height: 40px;
            border-radius: 0;
        }

        /deep/ .ant-calendar-range-picker-separator {
            line-height: 30px;
        }
    }
</style>